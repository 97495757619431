import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserData} from '../../interfaces/user-data';
import {NotificationPollingMessage} from '../../../core/interfaces/notification-polling-message';
import {NotificationType} from '../../../core/interfaces/notification-type';
import {NotificationCategoryType} from '../../../core/interfaces/notification-category-type';
import {HubSchoolAlertData} from '../../../hub/interfaces/hub-school-alert-data';
import {HubSchoolAlertCategoriesData} from '../../../hub/interfaces/hub-school-alert-categories-data';
import {HubSchoolAlertCategories} from '../../../hub/enums/hub-school-alert-categories';
import {ApiResponse} from '../../../core/interfaces/api-response';
import {AdminUserSessionsList} from '../../interfaces/admin-user-sessions-list';
import {AdminUserSession} from '../../interfaces/admin-user-session';
import {UserPermissions} from '../../interfaces/user-permissions';
import {AdminUserListData} from '../../interfaces/admin-user-list-data';

@Injectable({
  providedIn: 'root'
})
export class AdminApiCommsService {

  private readonly adminUrl = environment.backendServer.mainPath + environment.backendServer.admin;
  private readonly notificationsUrl = environment.backendServer.mainPath + environment.backendServer.notifications;
  private readonly schoolAlertsUrl = environment.backendServer.mainPath + environment.backendServer.schoolAlerts;

  constructor(private http: HttpClient) { }

  getAllUsers(): Observable<UserData[]> {
    const url = `${this.adminUrl}/users/all`;
    return this.http.get<UserData[]>(url);
  }

  getUsersExport(searchValue: string, orderBy: string, sortDir: string, permissions: string): Observable<Blob> {
    const params = {
      searchValue: searchValue,
      orderBy: orderBy,
      sortDir: sortDir,
      permissions: permissions
    };

    const paramsStr = Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join('&');

    const url = `${this.adminUrl}/users/export${paramsStr ? `?${paramsStr}` : ''}`;
    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  getUsers(searchValue: string, orderBy: string, sortDir: string, limit: number, offset: number,
           permissions: JSON): Observable<AdminUserListData> {
    const params = {
      searchValue: searchValue,
      orderBy: orderBy,
      sortDir: sortDir,
      limit: limit,
      offset: offset,
      permissions: permissions
    };

    const paramsStr = Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join('&');

    const url = `${this.adminUrl}/users${paramsStr ? `?${paramsStr}` : ''}`;
    return this.http.get<AdminUserListData>(url);
  }

  addNewUser(username: string, firstName: string, lastName: string): Observable<UserData> {
    const url = `${this.adminUrl}/users`;
    return this.http.post<UserData>(url, {
      username: username,
      firstName: firstName,
      lastName: lastName
    });
  }

  updateUserActiveStatus(userId: number, active: boolean): Observable<UserData> {
    const url = `${this.adminUrl}/users/${userId}/active`;
    return this.http.put<UserData>(url, {
      active: active
    });
  }

  updateUserAdminStatus(userId: number, admin: boolean): Observable<UserData> {
    const url = `${this.adminUrl}/users/${userId}/admin`;
    return this.http.put<UserData>(url, {
      admin: admin
    });
  }

  updateUserPermissions(userId: number, permissions: UserPermissions): Observable<UserData> {
    const url = `${this.adminUrl}/users/${userId}/permissions`;
    return this.http.put<UserData>(url, permissions);
  }

  updateUserDetails(userId: number, firstName: string, lastName: string): Observable<UserData> {
    const url = `${this.adminUrl}/users/${userId}/details`;
    return this.http.put<UserData>(url, {
      firstName: firstName,
      lastName: lastName
    });
  }

  getUserNotifications(offset?: number): Observable<NotificationPollingMessage[]> {
    const url = `${this.notificationsUrl}/current-user` + (offset ? `/${offset}` : '');
    return this.http.get<NotificationPollingMessage[]>(url);
  }

  createAndAssignNotifications(notification: NotificationPollingMessage): Observable<NotificationPollingMessage[]> {
    const url = `${this.notificationsUrl}/new`;
    return this.http.post<NotificationPollingMessage[]>(url, {
      category: notification.category.category,
      type: notification.type,
      title: notification.title,
      summary: notification.summary,
      detail: notification.detail,
      forceShow: notification.forceShow,
      emailNotification: notification.emailNotification,
      scheduleNotification: notification.scheduleNotification,
      scheduleDate: notification.scheduleDate,
      users: notification.users,
      metadata: notification.metadata
    });
  }

  createAndAssignNotificationAllUsers(notification: NotificationPollingMessage): Observable<NotificationPollingMessage[]> {
    const url = `${this.notificationsUrl}/new/all`;
    return this.http.post<NotificationPollingMessage[]>(url, {
      category: notification.category.category,
      type: notification.type,
      title: notification.title,
      summary: notification.summary,
      detail: notification.detail,
      forceShow: notification.forceShow,
      emailNotification: notification.emailNotification,
      scheduleNotification: notification.scheduleNotification,
      scheduleDate: notification.scheduleDate,
      metadata: notification.metadata
    });
  }

  getNotificationsTypes(): Observable<NotificationType[]> {
    const url = `${this.notificationsUrl}/types`;
    return this.http.get<NotificationType[]>(url);
  }

  getCategoryTypes(): Observable<NotificationCategoryType[]> {
    const url = `${this.notificationsUrl}/categories`;
    return this.http.get<NotificationCategoryType[]>(url);
  }

  getScheduledNotifications(): Observable<NotificationPollingMessage[]> {
    const url = `${this.notificationsUrl}/scheduled`;
    return this.http.get<NotificationPollingMessage[]>(url);
  }

  markNotificationAsRead(notifications: number[]): Observable<boolean> {
    const url = `${this.notificationsUrl}/mark-as-read`;
    return this.http.post<boolean>(url, {
      notifications: notifications
    });
  }

  cancelScheduledNotification(notificationId: number): Observable<boolean> {
    const url = `${this.notificationsUrl}/${notificationId}/cancel`;
    return this.http.put<boolean>(url, {});
  }

  getSchoolAlertCategories(): Observable<HubSchoolAlertCategoriesData[]> {
    const url = `${this.schoolAlertsUrl}/categories`;
    return this.http.get<HubSchoolAlertCategoriesData[]>(url);
  }

  getAllSchoolAlerts(): Observable<HubSchoolAlertData[]> {
    const url = `${this.schoolAlertsUrl}/alerts/all`;
    return this.http.get<HubSchoolAlertData[]>(url);
  }

  createNewSchoolAlert(schoolId: number, category: HubSchoolAlertCategories, title: string,
                       message: string): Observable<HubSchoolAlertData> {
    const url = `${this.schoolAlertsUrl}/alerts/new`;
    return this.http.post<HubSchoolAlertData>(url, {
      schoolId: schoolId,
      category: category,
      title: title,
      message: message
    });
  }

  updateSchoolAlert(alertId: number, category: HubSchoolAlertCategories, title: string, message: string): Observable<HubSchoolAlertData> {
    const url = `${this.schoolAlertsUrl}/alerts/${alertId}/update`;
    return this.http.put<HubSchoolAlertData>(url, {
      category: category,
      title: title,
      message: message
    });
  }

  publishSchoolAlert(alertId: number): Observable<HubSchoolAlertData> {
    const url = `${this.schoolAlertsUrl}/alerts/${alertId}/publish`;
    return this.http.put<HubSchoolAlertData>(url, {});
  }

  unpublishSchoolAlert(alertId: number): Observable<HubSchoolAlertData> {
    const url = `${this.schoolAlertsUrl}/alerts/${alertId}/unpublish`;
    return this.http.put<HubSchoolAlertData>(url, {});
  }

  resendWelcomeEmail(userId: number): Observable<ApiResponse<any>> {
    const url = `${this.adminUrl}/users/${userId}/welcome`;
    return this.http.post<ApiResponse<any>>(url, {});
  }

  getUserSessionList(searchValue: string, orderBy: string, sortDir: string,
                     limit: number, offset: number): Observable<AdminUserSessionsList> {
    const params = {
      searchValue: searchValue,
      orderBy: orderBy,
      sortDir: sortDir,
      limit: limit,
      offset: offset
    };

    const paramsStr = Object.keys(params).filter(k => params[k]).map(k => `${k}=${params[k]}`).join('&');

    const url = `${this.adminUrl}/user-sessions/list${paramsStr ? `?${paramsStr}` : ''}`;
    return this.http.get<AdminUserSessionsList>(url);
  }

  getUserSession(sid: string): Observable<AdminUserSession> {
    const url = `${this.adminUrl}/user-sessions/${sid}`;
    return this.http.get<AdminUserSession>(url);
  }

  updateNotification(notificationId: number, notification: NotificationPollingMessage): Observable<any> {
    const url = `${this.notificationsUrl}/edit-notifications/${notificationId}`;
    return this.http.put(url, {
      category: notification.category.category,
      type: notification.type,
      title: notification.title,
      summary: notification.summary,
      detail: notification.detail,
      forceShow: notification.forceShow,
      emailNotification: notification.emailNotification,
      scheduleNotification: notification.scheduleNotification,
      scheduleDate: notification.scheduleDate,
      users: notification.users,
      metadata: notification.metadata
    });
  }

  getUsersForNotification(notificationId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.notificationsUrl}/users/${notificationId}`);
  }
  
}
