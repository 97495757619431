import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { AdminApiCommsService } from '../admin-api-comms/admin-api-comms.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserFavouritesService {
  private readonly coreUrl = environment.backendServer.mainPath + environment.backendServer.eagleEye;

  constructor(private adminApiComms: AdminApiCommsService,
    private http: HttpClient
  ) { }


  getFavorites(userId: number): Observable<number[] | null> {
    return this.http.get<any>(`${this.coreUrl}/favorites/${userId}`)
      .pipe(
        //tap(response => console.log('Response from getFavorites:', response)),
        map(response => {
          if (response === null || response.favorite_layer_ids === null) {
            return null;
          } else if (Array.isArray(response)) {
            const layerIds = response.map(fav => fav.layer_id);
            return layerIds;
          } else if (response && Array.isArray(response.favorite_layer_ids)) {
            return response.favorite_layer_ids;
          } else {
            console.error('Unexpected response format:', response);
            return [];
          }
        }),
        catchError(error => {
          console.error('Error in getFavorites:', error);
          return of([]); 
        })
      );
  }
  

  addFavorite(userId: number, layerId: number): Observable<void> {
    return this.http.post<void>(`${this.coreUrl}/favorites`, { userId, layerId });
  }

  removeFavorite(userId: number, layerId: number): Observable<void> {
    return this.http.delete<void>(`${this.coreUrl}/favorites`, { body: { userId, layerId } });
  }

}
