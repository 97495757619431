import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {AppService} from './core/services/app/app.service';
import { MapFavoriteLayerResolverService } from './core/resolvers/map-favorite-layer-resolver/map-favorite-layer-resolver.service';

@Component({
  selector: 'ee-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(
    private titleService: Title,
    private appService: AppService,
    private mapFavLayersService: MapFavoriteLayerResolverService
  ) {
    if (environment.environment && !environment.production) {
      const title = environment.preview ? 'PREVIEW' : environment.environment;
      this.titleService.setTitle(this.titleService.getTitle() + ' - ' + title);
    }
  }

  ngOnInit() {
    //Save favorites to local storage
      this.mapFavLayersService.resolve().subscribe(favoriteLayers => {
          const uniqueFavorites = Array.from(new Set(favoriteLayers));
          const serializedFavorites = JSON.stringify(uniqueFavorites);
          localStorage.setItem('favoriteLayers', serializedFavorites);
          localStorage.setItem('favoriteLayersUpdate', Date.now().toString());
      });
  }

  onActivate(event: any) {
    this.appService.setRouterOutletActive();
  }
}
