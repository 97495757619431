<div class="map-panel" fxLayout="row" *ngIf="visible"
     [ngClass]="{'transport': currentMeasurement && currentMeasurement.mode === 'measure_travel_transport'}">
  <div class="menu-container">
    <button mat-icon-button [matMenuTriggerFor]="measurePanelMenu"
            *ngIf="currentMeasurement &&
            (currentMeasurement.mode === 'measure_polygon' || currentMeasurement.mode === 'measure_radius')"
            [disabled]="!(currentMeasurement && currentMeasurement.id &&
            (currentMeasurement.mode === 'measure_polygon' || currentMeasurement.mode === 'measure_radius'))">
      <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
    </button>

    <mat-menu #measurePanelMenu class="measure-panel-menu">
      <button mat-menu-item (click)="analyseInZhuri()">
        <mat-icon [svgIcon]="'zhuri-logo'" class="zhuri-logo"></mat-icon>
        <span>Analyse in Zhuri</span>
      </button>
      <button mat-menu-item (click)="addToGap()">
        <mat-icon fontSet="fal" fontIcon="fa-map-marked-alt"></mat-icon>
        <span>Create measurement in GAP</span>
      </button>
      <button mat-menu-item (click)="exportAsGeoJSON()">
        <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
        <span>Export as GeoJSON</span>
      </button>
      <button mat-menu-item (click)="copyEncodedPolygon()">
        <mat-icon fontSet="fas" fontIcon="fa-copy"></mat-icon>
        <span>Copy Encoded Polygon</span>
      </button>
    </mat-menu>
  </div>

  <mat-tab-group class="measure-panel" (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedTabIndex">
    <mat-tab [label]="getTitle()">
      <div class="tab-content" [perfectScrollbar]>
        <!-- Line data -->
        <div class="data" *ngIf="currentMeasurement?.mode === MapMeasureModeEnum.MEASURE_LINE">
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Length</div>
            <div class="values">
              {{currentMeasurement?.result?.displayLength ? currentMeasurement.result.displayLength : '0 m'}}
            </div>
          </div>
        </div>

        <!-- Polygon data -->
        <div class="data" *ngIf="currentMeasurement?.mode === MapMeasureModeEnum.MEASURE_POLYGON">
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Area</div>
            <div class="values">
              {{currentMeasurement?.result?.displayArea ? currentMeasurement.result.displayArea : '0 m'}}
            </div>
          </div>
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Perimeter:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayPerimeter ? currentMeasurement.result.displayPerimeter : '0 m'}}
            </div>
          </div>
        </div>

        <!-- Radius data -->
        <div class="data" *ngIf="currentMeasurement?.mode === MapMeasureModeEnum.MEASURE_RADIUS">
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Radius</div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field class="field measure-panel">
                <!-- Ignore this error -->
                <input matInput [(ngModel)]="currentRadius"
                       [disabled]="!radiusEditEnabled">
                <span matSuffix>km</span>
              </mat-form-field>
              <button mat-button (click)="setRadius()" class="simple-blue-button" [disabled]="currentMeasurement && !currentMeasurement.id">
                <span *ngIf="!radiusEditEnabled">EDIT</span>
                <span *ngIf="radiusEditEnabled">SET</span>
              </button>
            </div>
          </div>

          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Circumference:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayCircumference ? currentMeasurement.result.displayCircumference : '0 m'}}
            </div>
          </div>
        </div>

        <!-- Travel mode data -->
        <div class="data" *ngIf="currentMeasurement && (currentMeasurement.mode === MapMeasureModeEnum.MEASURE_TRAVEL_WALKING ||
                                                      currentMeasurement.mode === MapMeasureModeEnum.MEASURE_TRAVEL_CYCLING ||
                                                      currentMeasurement.mode === MapMeasureModeEnum.MEASURE_TRAVEL_DRIVING)">
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Distance</div>
            <div class="values">
              {{currentMeasurement?.result?.displayDistance ? currentMeasurement.result.displayDistance : '0 m'}}
            </div>
          </div>
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center">
            <div class="label">Duration:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayDuration ? currentMeasurement.result.displayDuration : '0 min'}}
            </div>
          </div>
        </div>

        <!-- Transport mode data -->
        <div class="data" *ngIf="currentMeasurement?.mode === MapMeasureModeEnum.MEASURE_TRAVEL_TRANSPORT">
          <div class="data-row" fxLayout="column">
            <div fxLayout="row" class="data-row">
              <mat-form-field class="field measure-panel timing">
                <mat-select [(value)]="mapObject?.measurementObject?.transportOptions.timing" (selectionChange)="updateTransportOptions()">
                  <mat-option value="arr">Arriving before</mat-option>
                  <mat-option value="dep">Departing after</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="field measure-panel date">
                <input matInput [matDatepicker]="measureDatePicker"
                       [matDatepickerFilter]="transportDateFilter"
                       [(ngModel)]="mapObject?.measurementObject?.transportOptions.date"
                       (click)="measureDatePicker.open()" (focus)="measureDatePicker.open()"
                       (dateChange)="updateTransportOptions()">
                <mat-datepicker-toggle matSuffix [for]="measureDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #measureDatePicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="field measure-panel time">
                <mat-select [(value)]="mapObject?.measurementObject?.transportOptions.time" (selectionChange)="updateTransportOptions()">
                  <mat-optgroup label="Morning">
                    <mat-option value="0700">7:00 am</mat-option>
                    <mat-option value="0730">7:30 am</mat-option>
                    <mat-option value="0800">8:00 am</mat-option>
                    <mat-option value="0830">8:30 am</mat-option>
                    <mat-option value="0900">9:00 am</mat-option>
                    <mat-option value="0930">9:30 am</mat-option>
                  </mat-optgroup>
                  <mat-optgroup label="Afternoon">
                    <mat-option value="1400">2:00 pm</mat-option>
                    <mat-option value="1430">2:30 pm</mat-option>
                    <mat-option value="1500">3:00 pm</mat-option>
                    <mat-option value="1530">3:30 pm</mat-option>
                    <mat-option value="1600">4:00 pm</mat-option>
                    <mat-option value="1630">4:30 pm</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxLayout="row" class="data-row">
              <mat-form-field class="field measure-panel mode">
                <mat-select multiple panelClass="measure-panel-modes"
                            [(value)]="mapObject?.measurementObject?.transportOptions.modes" (selectionChange)="updateTransportOptions()">
                  <mat-option [value]="TransportTripModeClassEnum.TRAIN">
                    <img src="assets/images/transport-icons/train-mode.svg">
                    <span>Train</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.BUS">
                    <img src="assets/images/transport-icons/bus-mode.svg">
                    <span>Bus</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.SCHOOL_BUS">
                    <img src="assets/images/transport-icons/school-bus-mode.svg">
                    <span>School Bus</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.FERRY">
                    <img src="assets/images/transport-icons/ferry-mode.svg">
                    <span>Ferry</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.METRO">
                    <img src="assets/images/transport-icons/metro-mode.svg">
                    <span>Metro</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.LIGHT_RAIL">
                    <img src="assets/images/transport-icons/light-rail-mode.svg">
                    <span>Light Rail</span>
                  </mat-option>
                  <mat-option [value]="TransportTripModeClassEnum.COACH">
                    <img src="assets/images/transport-icons/coach-mode.svg">
                    <span>Coach</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-button class="simple-blue-button" (click)="updateTransportMeasurement()"
                      [disabled]="!currentMeasurement.result || !hasTransportOptionsChanged">
                UPDATE
              </button>
            </div>
          </div>

          <div class="data-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="!failed">
            <div class="label">Departure Time:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayDepartureTime ? currentMeasurement.result.displayDepartureTime : '-'}}
            </div>
          </div>
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="!failed">
            <div class="label">Arrival Time:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayArrivalTime ? currentMeasurement.result.displayArrivalTime : '-'}}
            </div>
          </div>
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="!failed">
            <div class="label">Duration:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayDuration ? currentMeasurement.result.displayDuration : '0 min'}}
            </div>
          </div>
          <div class="data-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="!failed">
            <div class="label">Distance:</div>
            <div class="values">
              {{currentMeasurement?.result?.displayDistance ? currentMeasurement.result.displayDistance : '0 m'}}
            </div>
          </div>
          <div class="data-row fail-message" fxLayout="row" fxLayoutAlign="start center" *ngIf="failed">
            <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"></mat-icon>
            <span>There is no available journey for this given route.</span>
          </div>

          <div id="map-measure-transport-chart" class="transport-chart" *ngIf="showTransportChart && !failed"></div>
        </div>


      </div>
    </mat-tab>

    <mat-tab label="Geography">
      <div class="tab-content" [perfectScrollbar]>
        <span *ngIf="!currentMeasurement?.georeferenceResults">Draw a measurement to view geography results.</span>
        <mat-accordion [multi]="true" *ngIf="currentMeasurement?.georeferenceResults">
          <!-- LGAs -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.lgas">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Local Government Areas</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.lgas ? currentMeasurement.georeferenceResults.lgas.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.lgas;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.LGA, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- Suburb -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.suburbs">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Suburbs</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.suburbs ? currentMeasurement.georeferenceResults.suburbs.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.suburbs;">
              {{item.value | titlecase}}
            </div>
          </mat-expansion-panel>

          <!-- NSW Districts -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.districts">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>NSW Districts</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.districts ? currentMeasurement.georeferenceResults.districts.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.districts;">
              {{item.value | titlecase}}
            </div>
          </mat-expansion-panel>

          <!-- AMU Regions -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.detRegions">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>AMU Regions</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.detRegions ? currentMeasurement.georeferenceResults.detRegions.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.detRegions;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.DET, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- State Electorates -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.stateElectorates">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>State Electorates</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.stateElectorates ? currentMeasurement.georeferenceResults.stateElectorates.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.stateElectorates;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.SED, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- Federal Electorates -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.federalElectorates">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Federal Electorates</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.federalElectorates ? currentMeasurement.georeferenceResults.federalElectorates.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.federalElectorates;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.FED, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- School Catchments -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.catchments">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>School Catchments</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.catchments ? currentMeasurement.georeferenceResults.catchments.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.catchments;">
              {{item.value | titlecase}}
            </div>
          </mat-expansion-panel>

          <!-- School Performance Directorates -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.operationalDirectorates">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Public Schools Directorates</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.operationalDirectorates ? currentMeasurement.georeferenceResults.operationalDirectorates.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.operationalDirectorates;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.OPERATIONAL_DIRECTORATE, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- Principal Networks -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.principalNetworks">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Principal Networks</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.principalNetworks ? currentMeasurement.georeferenceResults.principalNetworks.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.principalNetworks;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.PRINCIPAL_NETWORK, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- DPIE Regions -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.dpieRegions">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>DPHI Regions</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.dpieRegions ? currentMeasurement.georeferenceResults.dpieRegions.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.dpieRegions;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.DPIE_REGION, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- Government Schools -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.governmentSchools">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Government Schools</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.governmentSchools ? currentMeasurement.georeferenceResults.governmentSchools.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.governmentSchools;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.SCHOOL, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- Non-Government Schools -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.nonGovernmentSchools">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>Non-Government Schools</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.nonGovernmentSchools ? currentMeasurement.georeferenceResults.nonGovernmentSchools.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.nonGovernmentSchools;">
              <a [routerLink]="['/', AppRoutesEnum.HUB, HubRoutesEnum.SCHOOL, item.id]">{{item.value | titlecase}}</a>
            </div>
          </mat-expansion-panel>

          <!-- DP Lots -->
          <mat-expansion-panel *ngIf="currentMeasurement?.georeferenceResults?.lots">
            <mat-expansion-panel-header collapsedHeight="24px" expandedHeight="24px">
              <mat-panel-title>DP Lots</mat-panel-title>
              <mat-panel-description>
                {{currentMeasurement.georeferenceResults?.lots ? currentMeasurement.georeferenceResults.lots.length : '-'}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let item of currentMeasurement.georeferenceResults.lots;">
              {{item.value | titlecase}}
            </div>
          </mat-expansion-panel>

        </mat-accordion>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div fxLayout="column" class="icon-container">
    <button mat-icon-button (click)="close()" class="close">
      <mat-icon fontSet="fal" fontIcon="fa-times"></mat-icon>
    </button>
    <button mat-icon-button (click)="delete()"
            eeTooltip tooltipText="Delete measurement" tooltipClass="toolbar-tooltips" [tooltipDirection]="'left'">
      <mat-icon fontSet="fal" fontIcon="fa-trash-alt"></mat-icon>
    </button>
    <button mat-icon-button (click)="analyseInHub()"
            *ngIf="currentMeasurement && currentMeasurement.id &&
            (currentMeasurement.mode === 'measure_polygon' || currentMeasurement.mode === 'measure_radius')"
            eeTooltip tooltipText="Analyse in HUB" tooltipClass="toolbar-tooltips" [tooltipDirection]="'left'">
      <mat-icon fontSet="fal" fontIcon="fa-magic"></mat-icon>
    </button>
  </div>
</div>
